<template>
	<div class="orderInfo">
		<el-row>
			<el-col :xs="0" :sm="0" :md="0" :lg="1" :xl="2">
			</el-col>
			<el-col :xs="24" :sm="24" :md="14" :lg="13" :xl="12">
				<div class="payBox">
					<h1>바이즈쿠</h1>
					<el-col :xs="24" :sm="24" :md="0" :lg="0" :xl="0">
						<el-collapse v-model="activeName" accordion>
						  <el-collapse-item name="1">
							  <template #title>
								<div style="width: 100%;display: flex;align-items: center;">
									<el-icon style="margin-right: 5px;" size="14"><ShoppingCart /></el-icon>
									<span style="font-size: 1.4rem;flex-grow: 1;">주문 요약 표시</span>
									<b style="font-size: 1.7rem;margin-right: 5px;" v-if="!activeName">${{totalMoney.toFixed(2)}}</b>
								</div>
							  </template>
							<div>
							  <OrderGoods />
							</div>
						  </el-collapse-item>
						</el-collapse>
					</el-col>
					<el-breadcrumb :separator-icon="ArrowRight">
					    <el-breadcrumb-item :to="{ path: '/cart' }">카트</el-breadcrumb-item>
					    <el-breadcrumb-item>정보</el-breadcrumb-item>
					    <el-breadcrumb-item>배송</el-breadcrumb-item>
					    <el-breadcrumb-item>지급</el-breadcrumb-item>
					</el-breadcrumb>
					<p>Express 결제를 선택하십시오.</p>
					<div class="payBtn">
						<el-col :xs="24" :sm="14" :md="14" :lg="12" :xl="12">
							<a class="paypal">PayPal</a>
						</el-col>
					</div>
					<el-divider><span style="color: #707070;">또는</span></el-divider>
					<div class="formBox">
						<div class="titleDes">
							<h2>연락처</h2>
							<span>계정을 이미 가지고 계십니까?<router-link class="btn" to="/account/login">로그인</router-link></span>
						</div>
						<div>
							<el-form :model="form">
								<el-form-item label="" prop="email">
									<el-input v-model="form.email" />
								</el-form-item>
								<el-form-item label="" prop="agree">
									<el-checkbox v-model="agree" label="뉴스 및 제안을 이메일로 받기" name="agree" />
								</el-form-item>
							</el-form>
						</div>
						<div class="btnBox">
							<router-link class="btn" to="/cart">
								<el-icon size="14"><ArrowLeft /></el-icon>
								장바구니로 돌아가기
							</router-link>
							<el-button type="primary" :disabled="!agree" @click="onSubmit">배송 계속하기</el-button>
						</div>
						<!-- <el-divider /> -->
						<!-- <div style="padding-bottom: 20px;margin-top: -10px;">
							<el-button link type="primary">환불 정책</el-button>
							<el-button link type="primary">개인정보처리방침</el-button>
							<el-button link type="primary">서비스 약관</el-button>
							<el-button link type="primary">연락처 정보</el-button>
						</div> -->
					</div>
				</div>
			</el-col>
			<el-col :xs="0" :sm="0" :md="10" :lg="10" :xl="10" style="background: #f5f5f5;">
				<div class="infoBox">
					<OrderGoods @getIds="getIds" @getTotal="getTotal" />
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script setup>
	import { ArrowRight,QuestionFilled,ShoppingCart } from '@element-plus/icons-vue'
	import OrderGoods from '@/views/cart/ordergood'
	import useUserStore from '@/store/modules/user'
	import { reactive, ref, onMounted } from 'vue'
	import { paypal } from '@/api/pay'
	
	const totalMoney = ref(0)
	
	const activeName = ref('')
	const squareUrl = ref('')
	const form = reactive({
		email: '',
		ids: ''
	})
	const agree = ref(false)
	onMounted(()=>{
		form.email = useUserStore().userInfo.email
	})
	
	const getTotal = (val) => {
		totalMoney.value = val
	}
	const getIds = (val) => {
		form.ids = val
	}
	const onSubmit = () => {
		console.log(form)
		paypal(form).then((res) => {
			if(res.data){
				window.open(res.data)
			}
			console.log(res)
		})
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.orderInfo{
		width: 100%;min-height: 100vh;
		.payBox{
			max-width: calc(638px + 5vw);
			min-height: 100vh;box-sizing: border-box;
			padding-top: 5.6rem;
			padding-inline-end: 5.4vw;
			padding-inline-start: 5vw;
			margin: 0 auto;
			.el-collapse{
				margin: 20px 0;
			}
			h1{
				margin: 0;font-size: 24px;
			}
			.el-breadcrumb{
				margin: 1.7rem 0 2.6rem;
			}
			p{
				text-align: center;font-size: 1.4rem;color: #707070;
			}
			.payBtn{
				height: 65px;display: flex;align-items: center;justify-content: center;
				.paypal{
					display: inline-block;background: orange;color: #fff;margin-top: 1.7rem;
					border-radius: 5px;width: 100%;text-align: center;height: 45px;line-height: 45px;
					font-size: 1.6rem;font-style: italic;font-weight: 500;letter-spacing: 0.06rem;
				}
			}
			.formBox{
				padding-top: 21px;
				.titleDes{
					margin-bottom: 12px;font-size: 1.4rem;
					display: flex;align-items: center;justify-content: space-between;
					h2{
						font-size: 1.8rem;
					}
					a{
						color: #409eff;text-decoration: underline;margin-left: 2px;
					}
				}
				.btnBox{
					font-size: 1.4rem;padding: 20px 0;
					display: flex;align-items: center;justify-content: space-between;
					a{
						color: #409eff;display: inline-flex;align-items: center;
						.el-icon{
							margin-right: 10px;
						}
					}
					.el-button{
						height: 47px;
					}
				}
			}
		}
		.infoBox{
			max-width: calc(462px + 5vw);
			min-height: 100vh;box-sizing: border-box;
			padding-top: 5.7rem;
			padding-block-start: 5.7rem;
			padding-inline-end: 5vw;
			padding-inline-start: 3.6vw;
		}
	}
</style>
