<template>
	<div class="goodInfo">
		<div class="goodItem" v-for="(item,index) in cartList" :key="index">
			<el-badge :value="item.num" type="info">
				<el-avatar shape="square" size="large" :src="item.img"></el-avatar>
			</el-badge>
			<p class="title">{{item.title}}</p>
			<span class="price">${{item.totalPrice}}</span>
		</div>
		<!-- <div class="moneyItem">
			<span>소계</span>
			<b>${{totalMoney.toFixed(2)}}</b>
		</div>
		<div class="moneyItem">
			<span>배송</span>
			<span style="font-size: 1.2rem;color: #666;">다음 단계에서 계산됨</span>
		</div>
		<div class="moneyItem">
			<span style="display: inline-flex;align-items: center;">
				예상 세금
				<el-tooltip class="item" effect="dark" content="최종 세금 및 합계 금액은 주문을 접수한 후에 이메일 또는 문자 메시지로 확정됩니다." placement="top">
				      <el-icon size="14" style="margin-left: 5px;"><QuestionFilled /></el-icon>
				</el-tooltip>
			</span>
			<b>${{totalMoney.toFixed(2)}}</b>
		</div> -->
		<div class="moneyItem">
			<span style="font-size: 1.7rem;">총계</span>
			<span style="font-size: 1.2rem;color: #666;">USD&nbsp;
				<b style="font-size: 1.7rem;color: rgb(18,18,18);">${{totalMoney.toFixed(2)}}</b>
			</span>
		</div>
	</div>
</template>

<script setup>
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { reactive, ref, onMounted, defineEmits } from 'vue'
	import useUserStore from '@/store/modules/user'
	import { getCartList } from '@/api/index'
	
	const isEmpty = ref(false)
	
	const cartList = ref([])
	const totalMoney = ref(0)
	const Ids = ref([])
	onMounted(()=>{
		if(useUserStore().userid){
			isEmpty.value = false
			init()
		}else{
			isEmpty.value = true
		}
	})
	const emit = defineEmits(["getTotal","getIds"])
	const init = () => {
		getCartList(useUserStore().userid).then((res) => {
			cartList.value = res.rows
			totalMoney.value = 0
			Ids.value = []
			res.rows.forEach((item) => {
				totalMoney.value += item.totalPrice
				Ids.value.push(item.id)
			})
			emit("getTotal", totalMoney.value)
			emit("getIds", Ids.value.toString())
		})
	}
</script>

<style lang="less" scoped>
	.goodInfo{
		width: 100%;
		.goodItem{
			padding: 1rem 0;
			display: flex;align-items: center;justify-content: flex-start;
			.el-badge{
				margin-right: 1.4rem;
			}
			.title{
				flex-grow: 1;font-size: 1.4rem;font-weight: 500;
			}
			.price{
				font-size: 1.4rem;font-weight: 500;
			}
		}
		.moneyItem{
			width: 100%;margin-top: 8px;font-size: 1.4rem;
			display: flex;align-items: center;justify-content: space-between;
		}
	}
</style>
